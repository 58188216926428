html {
    scroll-padding-top: 55px;
    font-family: Helvetica, sans-serif;
    font-weight: 100;
  }

h1 {
    margin: 15px;
    font-weight: 100;
    font-size: 50px;
}

h2 {
    font-weight: 100;
    font-size: 30px;
}

.new-section {
    font-weight: 700;
    font-size: 40px;
    font-variant: small-caps;

}

h4 {
    font-weight: 100;
}

p {
    font-weight: 100;
}

.entry-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-container {
    display: flex;
    justify-content: space-between;

    column-gap: 5px;
    padding : 5px;
}

.generic-button {
    background-color: transparent;
    border-style: none;
    font-weight: 500;
    
    padding: 0px;
}

.generic-button:hover {
    color: brown;
}

.generic-button:active {
    color: chocolate;
}

.login-button-container {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}
 

.flexbox-header {
    position: sticky;
    top: 10px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    background-color: white;
    
    padding: 15px;
    height: 60px;

}

.header-link {
    text-decoration: none;
    color:black;
}

.header-link:hover{
    color:brown;
}

.header-link:active{
    color:chocolate;
}

.user-input {
    display: grid;
    margin-bottom: 5px;
}

#headingsSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 5vh;
    margin-bottom: 5vh;

    /*
    min-width: 765px;
    */
}

.music-div{
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

/*
@media (max-width: 765px) {
    .music-div, .music-container {
      flex-direction: column;
      column-gap: 0cap;
      text-align: start;
      row-gap: 2px;
      align-items: start;
    }
  }
*/


.music-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    column-gap: 10px;

    font-size: 20px;
}

.outer-highlight-container {
    padding:10px;
}

.highlight-group {
    display: flex;
    justify-content: center;

    margin-top: 5px;
    margin-bottom: 5px;
}

.highlight-button {

    border-style: none;
}

.highlight-button:hover {
    font-size: larger;
}

.highlight-image {
   max-width: 100%;
   max-height: 85vh;
}

.bs-button-container {
    display:flex;
    justify-content: center;
    column-gap: 20px;

}

.highlight-background {
    background-color: white;
    padding: 10px 10px 10px 10px;
}

.single-image {
    height: 180px;
    padding: 1px;
}

.single-image-hidden {
    height: 100px;
    padding: 10px;
    opacity: 0%;

}

.loading-text {
    text-align: center;
}

.image-button, .hidden-image {
    background-color: white;

    border-style: none;
    
    margin-bottom: 2px;

    filter:grayscale(100%)

}

.image-button:hover, .hidden-image:hover {
    filter:grayscale(0%);
    
}

.image-button:active {
    filter:grayscale(0%)
    
}

.hidden-image {
    opacity : .5;
}

.group-hidden {
    visibility:collapse;
}

.image-grouping {
    display: flex;
    align-content:flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;

    row-gap: 1px;
    column-gap: 1px;
}

.under-image-container {
    display: flex;
    flex-wrap: wrap;

    width: 200px;
}

.language-container {
    display: flex;
    
    column-gap: 5px;
}

.flag-icon {
    width: 30px;
}

.flag-button {
    border-style:hidden;

    background-color: transparent;

    padding: 0px;
}

.flag-button:hover {
    opacity: .7;
}

.flag-button:active {
    opacity: .5;
}

.error-message {
    color: red;
    font-style: italic;

    margin-bottom: 10px;
}

.scene-filter-container {
    display: flex;
    
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    gap: 5px;
    margin-bottom: 10px;
}

.scene-filter-container button {
    background-color: transparent;
    border-style: none;

    font-size: 18px;
}

.scene-filter-container button:hover {
    color: brown
}

.scene-filter-container button:active {
    color: chocolate;
}

.scene-name-highlight {
    color: darkblue;
    font-weight: 300;
}

.scene-name-regular {
    color: black;
    font-weight: 200;
}

.scene-linked {
    opacity: 1
}

.scene-unlinked {
    opacity : .5
}

.regular-text {
    color:black;
    text-decoration: none;
}

.regular-text:hover {
    color:white;
}

.single-image-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.login-container {
    padding : 10px;
}